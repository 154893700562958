const wordings = {
  tmFeedback: "TM Feedback",
  feedback: "Feedback",
  token: "Token",
  areaId: "Area ID",
  areaName: "Area Name",
  secondLanguage: "Second Language",
  back: "Back",
  save: "Save",
  default: "Default",
  close: "Close",
  edit: "Edit",
  settings: "Settings",
  phoneNumber : "phoneNumber",
};

export default wordings;

export const englishWordings = {
  rateUs: "Rate your experience",
  bad: "Bad",
  good: "Good",
  excellent: "Excellent",
  submit: "Submit",
  morning: "Good Morning!",
  afternoon: "Good Afternoon!",
  evening: "Good Evening!",
  name: "Name",
  number: "Phone Number",
  numberError: "Please enter a valid phone number",
};

export const hindiWordings = {
  rateUs: "अपने अनुभव को रेट करें",
  bad: "खराब",
  good: "अच्छा",
  excellent: "उत्कृष्ट",
  submit: "जमा करना",
  morning: "शुभ प्रभात",
  afternoon: "शुभ दोपहर",
  evening: "शुभ संध्या",
  name: "नाम",
  number: "फ़ोन नंबर",
  numberError: "एक मान्य दूरभाष क्रमांक दर्ज करे",
};

export const marathiWordings = {
  rateUs: "तुमचा अनुभव रेट करा",
  bad: "वाईट",
  good: "चांगले",
  excellent: "उत्कृष्ट",
  submit: "प्रस्तुत करणे",
  morning: "शुभ प्रभात",
  afternoon: "शुभ दुपार",
  evening: "शुभ संध्या",
  name: "नाव",
  number: "फोन नंबर",
  numberError: "कृपया वैध दूरध्वनी क्रमांक टाका",
};
