export const updateTimeOfDay = () => {
  const currentHour = new Date().getHours();

  if (currentHour < 12) {
    return "morning";
  } else if (currentHour < 16) {
    return "afternoon";
  } else {
    return "evening";
  }
};
