import React, { useMemo, useRef } from "react";
import { IoMdClose } from "react-icons/io";
import { validatePhoneNumber } from "./validatePhoneNumber";
import { joinClass, languageSelection } from "../../helpers/utils";
import Modal from "../Modal";
import styles from "./index.module.css";
import wordings from "../../pages/Home/wordings";

function Popup({
  isOpen,
  toggleOpen,
  rates,
  userRate,
  handleSubmit,
  language,
  isLoading,
  setUserRate,
  inputData,
  setInputData,
  initialState,
  phoneNumberError,
  setPhoneNumberError,
}) {
  const nameInputRef = useRef(null);
  const phoneInputRef = useRef(null);
  const phoneNumberErrorMessage = languageSelection(
    language?.id,
    "numberError"
  );

  const selectedItem = useMemo(() => {
    return rates.find((rate) => rate.id === userRate);
  }, [rates, userRate]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputData({
      ...inputData,
      [name]: value,
    });
    if (name === wordings.phoneNumber) {
      setPhoneNumberError("");
      const isPhoneNumberValid = validatePhoneNumber(value);
      if (!isPhoneNumberValid) {
        setPhoneNumberError(phoneNumberErrorMessage);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (e.target === phoneInputRef.current) {
        handleSubmit();
      } else if (e.target === nameInputRef.current) {
        phoneInputRef.current.focus();
      }
    }
  };

  const handleCancel = () => {
    toggleOpen(false);
    setUserRate(0);
    setInputData(initialState);
  };

  return (
    <Modal
      isOpen={isOpen}
      onCancel={() => {
        handleCancel();
      }}
    >
      <section className={styles["popup-main"]}>
        <div
          className={joinClass(
            styles["popup__main-container"],
            styles[`popup__main_container-${selectedItem.key}`]
          )}
        >
          <div
            className={styles["popup-close"]}
            onClick={() => {
              handleCancel();
            }}
          >
            <IoMdClose />
          </div>
          <div className={styles["popup_main-label"]}>{selectedItem.label}</div>
          <img
            src={selectedItem.gif}
            alt="emoji-gif"
            className={styles["popup__main-icon"]}
          />
          <div className={styles["popup_input-userdata"]}>
            <div className={styles["popup-input_group"]}>
              <label htmlFor="name">
                {languageSelection(language?.id, "name")}
              </label>
              <input
                type="text"
                name="username"
                value={inputData.username}
                onChange={handleChange}
                onKeyDown={handleKeyPress}
                ref={nameInputRef}
              />
            </div>
            <div className={styles["popup-input_group"]}>
              <label htmlFor="phonenumber">
                {languageSelection(language?.id, "number")}
              </label>
              <input
                type="text"
                name="phoneNumber"
                value={inputData.phoneNumber}
                onChange={handleChange}
                onKeyDown={handleKeyPress}
                ref={phoneInputRef}
                inputMode="numeric"
              />
            </div>
            <p>{phoneNumberError}</p>
          </div>
          <button
            className={styles["popup-__main-button"]}
            onClick={
              validatePhoneNumber(inputData.phoneNumber) ? handleSubmit : null
            }
          >
            {isLoading && <div className={styles["popup__loader"]} />}
            {languageSelection(language?.id, "submit")}
          </button>
        </div>
      </section>
    </Modal>
  );
}

export default Popup;
