import React, { useEffect, useState } from "react";
import { TfiLocationPin } from "react-icons/tfi";
import { joinClass, languageSelection } from "../../helpers/utils";
import Toaster from "../../components/Toaster";
import Popup from "../../components/Popup";
import Settings from "./Settings";
import Greeting from "./Settings/Greeting";
import { GIF, IMAGES } from "./constants";
import small_logo from "../../assets/small_logo.png";
import styles from "./index.module.css";

const CustomIcon = ({ userRate, id, img }) => {
  return (
    <img
      src={img}
      alt="emoji-image"
      className={
        styles[`icon__container-image${userRate === id ? "-active" : ""}`]
      }
    />
  );
};

const Home = () => {
  const [isOpenSettings, toggleOpenSettings] = useState(false);
  const [isOpenRated, toggleOpenRated] = useState(false);
  const [userRate, setUserRate] = useState(0);
  const [isLoading, toggleLoading] = useState(false);
  const [message, setMessage] = useState({ text: "", type: "" });
  const [language, setLanguage] = useState({ id: "en", label: "English" });
  const [token, setToken] = useState("");
  const [areaId, setAreaId] = useState("");
  const [initialSetupCompleted, toggleInitialSetupCompleted] = useState(true);
  const [areaName, setAreaName] = useState("");
  const [notes, setNotes] = useState("");
  const initialState = {
    username: "",
    phoneNumber: "",
  };
  const [inputData, setInputData] = useState(initialState);
  const [phoneNumberError, setPhoneNumberError] = useState("");

  const userName= inputData.username;
  const userPhone= inputData.phoneNumber;
   
  useEffect(() => {
    let timer;
    const startTimer = () => {
      timer = setTimeout(() => {
        setUserRate(0);
        setInputData(initialState);
      }, 60000);
    };

    if (userRate !== 0) {
      startTimer();
    }

    return () => clearTimeout(timer);
  }, [userRate]);

  useEffect(() => {
    const localToken = localStorage.getItem("token");
    const localAreaId = localStorage.getItem("areaId");
    const localLanguage = localStorage.getItem("language");
    const localAreaName = localStorage.getItem("areaName");

    if (!localToken) {
      toggleInitialSetupCompleted(false);
      toggleOpenSettings(true);
    } else {
      setAreaId(localAreaId);
      setToken(localToken);
      setAreaName(localAreaName);
      toggleInitialSetupCompleted(true);
      if (JSON.parse(localLanguage)) setLanguage(JSON.parse(localLanguage));
    }
  }, [isOpenSettings, userRate, notes]);

  const RATES = [
    {
      key: "bad",
      id: 1,
      label: languageSelection(language?.id, "bad"),
      img: IMAGES[0],
      gif: GIF[0],
    },
    {
      key: "good",
      id: 2,
      label: languageSelection(language?.id, "good"),
      img: IMAGES[1],
      gif: GIF[1],
    },
    {
      key: "excellent",
      id: 3,
      label: languageSelection(language?.id, "excellent"),
      img: IMAGES[2],
      gif: GIF[2],
    },
  ];

  const settingsProps = {
    language,
    setLanguage,
    token,
    setToken,
    areaId,
    setAreaId,
    initialSetupCompleted,
    toggleInitialSetupCompleted,
    areaName,
    setAreaName,
  };

  const BASE_URL = `${process.env.REACT_APP_API_URL}/edge-api`;

  const handleSelect = (id) => {
    if (userRate === id) {
      setUserRate(0);
    } else {
      setUserRate(id);
      toggleOpenRated(true);
    }
  };

  const handleSubmit = () => {
    if (userRate > 0) {
      try {
        toggleLoading(true);
        fetch(`${BASE_URL}/v1/area/feedback/${areaId}/submit-feedback`, {
          method: "PUT",
          mode: "cors",
          body: JSON.stringify({
            rating: userRate,
            name:userName,
            phone:userPhone
          }),
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: token,
          }),
        })
          .then((response) => response?.json())
          .then((data) => {
            if (data?.status) {
              setUserRate(0);
              setInputData(initialState);
              setMessage({
                text: "Thanks for your response...",
                type: "success",
              });
            } else {
              setMessage({
                text: data?.message,
                type: "error",
              });
            }
            toggleLoading(false);
          })
          .catch((error) => {
            toggleLoading(false);
            setMessage({ text: error?.message, type: "error" });
          });
      } catch (error) {
        toggleLoading(false);
        setMessage({ text: error?.message, type: "error" });
      }
    }
  };

  const popupProps = {
    isOpen: isOpenRated,
    toggleOpen: toggleOpenRated,
    rates: RATES,
    userRate,
    handleSubmit,
    language,
    isLoading,
    setUserRate,
    inputData,
    setInputData,
    initialState,
    phoneNumberError,
    setPhoneNumberError,
  };

  return (
    <div className={styles.home}>
      {message.text && <Toaster message={message} setMessage={setMessage} />}
      <Settings
        isOpen={isOpenSettings}
        toggleOpen={toggleOpenSettings}
        {...settingsProps}
      />
      <header className={styles["home__header"]}>
        <div className={styles["home__header-text"]}>
          <div className={styles["home__header-title"]}>
            {languageSelection(language?.id, "rateUs")}
          </div>
          <div className={styles["home__header-settings"]}>
            <div className={styles["home__img"]}>
              <img src={small_logo} alt="company logo" />
            </div>
          </div>
        </div>
        <div className={styles["home__greeting-text"]}>
          <Greeting language={language?.id} />
        </div>
        <div className={styles["home__location"]}>
          <TfiLocationPin fontSize={22} />
          <div className={styles["home__card-header"]}>{areaName}</div>
        </div>
      </header>
      {userRate > 0 && <Popup {...popupProps} />}
      <section className={styles["home__main"]}>
        <div className={styles["home__card"]}>
          <div className={styles["home__icon-btns"]}>
            {RATES.map(({ id, key, label, img }) => (
              <div
                className={`${styles[`home__icon-container-${key}`]}`}
                key={key}
              >
                <div
                  className={
                    styles[`home__icon-btn${userRate === id ? "-active" : ""}`]
                  }
                  onClick={() => handleSelect(id)}
                >
                  <div
                    className={joinClass(
                      styles[
                        `home__icon${userRate === id ? `-active-${key}` : ""}`
                      ],
                      styles[`home__icon-${key}`]
                    )}
                  >
                    <CustomIcon userRate={userRate} id={id} img={img} />
                  </div>
                </div>
                <div className={styles["home__label"]}>{label}</div>
              </div>
            ))}
          </div>
          <button
            className={styles["home__button-hidden"]}
            onClick={() => toggleOpenSettings(!isOpenSettings)}
          >
            Settings
          </button>
        </div>
      </section>
    </div>
  );
};
export default Home;
