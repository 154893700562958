import badSvg from "../../assets/images/bad_image_svg.svg";
import goodSvg from "../../assets/images/good_image_svg.svg";
import xlntSvg from "../../assets/images/xlnt_image_svg.svg";
import badGif from "../../assets/images/bad_image_gif.gif";
import goodGif from "../../assets/images/good_image_gif.gif";
import xlntGif from "../../assets/images/xlnt_image_gif.gif";

export const LANGUAGES = [
  { id: "en", label: "English" },
  { id: "hi", label: "Hindi" },
  { id: "ma", label: "Marathi" },
];

export const IMAGES = [badSvg, goodSvg, xlntSvg];
export const GIF = [badGif, goodGif, xlntGif];