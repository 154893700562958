import React from "react";
import { joinClass } from "../../helpers/utils";
import { TiTick } from "react-icons/ti";
import { RiCloseCircleFill } from "react-icons/ri";
import wordings from "./wordings";
import styles from "./index.module.css";

const Toaster = ({ message, setMessage }) => {
  setTimeout(() => {
    if (setMessage) setMessage({ type: "", text: "" });
  }, 2300);

  const iconType = {
    success: (
      <div className={styles["toaster__success-icon"]}>
        <TiTick fontSize={22} />
      </div>
    ),
    error: <RiCloseCircleFill fontSize={22} />,
  };

  const messageType = {
    success: wordings.success,
    error: wordings.error,
  };

  return (
    <div className={joinClass(styles['toaster-main'],styles[`toaster_main_bg-${message?.type}`])}>
      <div className={joinClass(styles["toaster"], styles[message?.type])}>
        {iconType[message?.type]}
        <div className={styles["toaster__message"]}>
          <label>{messageType[message?.type]}</label>
          <label>{message?.text}</label>
        </div>
      </div>
    </div>
  );
};

export default Toaster;
