import {
  englishWordings,
  hindiWordings,
  marathiWordings,
} from "../pages/Home/wordings";

export const joinClass = (...classNames) => classNames.join(" ");

export const languageSelection = (languageType, wordingsKey) => {
  if (languageType === "en") {
    return englishWordings[wordingsKey];
  } else if (languageType === "hi") {
    return englishWordings[wordingsKey] + " / " + hindiWordings[wordingsKey];
  } else if (languageType === "ma") {
    return englishWordings[wordingsKey] + " / " + marathiWordings[wordingsKey];
  }
};
