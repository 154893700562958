import { joinClass } from "../../helpers/utils";
import styles from "./index.module.css";

const Button = ({
  children,
  className,
  gradientButton,
  transparent,
  shadow,
  alter,
  onClick,
  disabled,
  styleReverse,
  cancelButton,
  type,
  isLoading,
}) => {
  return (
    <div className={styles["button__container"]}>
      {isLoading && <div className={styles["button__loader"]} />}
      <button
        className={joinClass(
          styles.button,
          gradientButton && styles["button--gradient"],
          transparent && styles["button--transparent"],
          shadow && styles["button--shadow"],
          alter && styles["button--alter"],
          styleReverse && styles["button--reverse"],
          cancelButton && styles["button--cancel"],
          className
        )}
        onClick={onClick}
        disabled={isLoading ? true : disabled}
        type={type}
      >
        {children}
      </button>
    </div>
  );
};

export default Button;
