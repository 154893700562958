import React, { useRef } from "react";
import Select from "react-select";
import { joinClass } from "../../helpers/utils";
import styles from "./index.module.css";

const Dropdown = (props) => {
  const {
    className,
    label,
    dropdownClass,
    labelClass,
    clearable,
    name,
    required,
    options,
    isNumberList,
    value,
    onChange,
    isMulti,
    disabled,
    isLoading,
    placeholder,
    cutomOptionStyles,
    ...rest
  } = props;

  const inputRef = useRef(null);

  const handleChange = (value) => {
    if (isMulti) {
      const updated = [...(value || [])];

      if (required)
        inputRef.current.value = (value?.length > 0 && "test") || "";
      onChange(updated);
      return;
    }

    if (required) inputRef.current.value = value?.id || value?.value || "";
    onChange(value);
  };

  const compare = (a, b) => {
    const { labelKey } = props;

    if (
      a &&
      b &&
      a[labelKey] &&
      b[labelKey] &&
      a[labelKey].toUpperCase() < b[labelKey].toUpperCase()
    ) {
      return -1;
    }
    if (
      a &&
      b &&
      a[labelKey] &&
      b[labelKey] &&
      a[labelKey].toUpperCase() > b[labelKey].toUpperCase()
    ) {
      return 1;
    }

    return 0;
  };

  if (options && !isNumberList) options.sort(compare);

  return (
    <div className={joinClass(styles.dropdown, className)}>
      {isLoading && (
        <div
          className={joinClass(
            styles.dropdown__loader,
            label && styles["dropdown__loader--label"]
          )}
        />
      )}
      {label && (
        <div className={styles["dropdown__label-container"]}>
          <label
            htmlFor={name}
            className={joinClass(
              styles["dropdown__label"],
              labelClass && labelClass
            )}
          >
            {label}
          </label>
          {required && (
            <label className={styles["dropdown__required-field"]}>{" *"}</label>
          )}
        </div>
      )}
      <Select
        {...rest}
        options={options}
        required={required}
        isClearable={clearable}
        id={name}
        className={dropdownClass}
        onChange={handleChange}
        getOptionLabel={(data) => {
          if ("name" in data) return data.name;
          return data.label;
        }}
        getOptionValue={(data) => {
          if ("id" in data) return data.id;
          return data.value;
        }}
        value={value}
        isMulti={isMulti}
        isDisabled={disabled}
        placeholder={placeholder}
        styles={cutomOptionStyles}
        isSearchable={false}
      />
      {required && (
        <input
          ref={inputRef}
          defaultValue={
            (isMulti && value?.length > 0 && "test") ||
            value?.id ||
            value?.value ||
            ""
          }
          tabIndex={-1}
          autoComplete="off"
          className={styles["dropdown__input"]}
          required={required}
        />
      )}
    </div>
  );
};

export default Dropdown;
