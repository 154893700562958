import React, { useState } from "react";
import Modal from "../../../components/Modal";
import Dropdown from "../../../components/Dropdown";
import TextInput from "../../../components/TextInput";
import Button from "../../../components/Button";
import { LANGUAGES } from "../constants";
import wordings from "../wordings";
import styles from "./index.module.css";

const Settings = ({
  isOpen,
  toggleOpen,
  language,
  setLanguage,
  token,
  setToken,
  areaId,
  setAreaId,
  initialSetupCompleted,
  toggleInitialSetupCompleted,
  areaName,
  setAreaName,
}) => {
  const [isEdit, toggleEdit] = useState(false);

  const onClose = () => {
    const localToken = localStorage.getItem("token");
    const localAreaId = localStorage.getItem("areaId");
    const localLanguage = localStorage.getItem("language");
    const localAreaName = localStorage.getItem("areaName");

    setAreaId(localAreaId);
    setToken(localToken);
    setAreaName(localAreaName);

    if (JSON.parse(localLanguage)) setLanguage(JSON.parse(localLanguage));
    toggleEdit(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onCancel={() => {
        if (initialSetupCompleted) {
          toggleOpen(false);
          toggleEdit(false);
        }
      }}
    >
      <div className={styles["settings"]}>
        <h2>{wordings.settings + (isEdit ? `/ ${wordings.edit}` : "")}</h2>
        {(isEdit || !initialSetupCompleted) && (
          <div className={styles["settings__input"]}>
            <TextInput
              label={wordings.token}
              value={token}
              onChange={(value) => setToken(value)}
              required
            />
            <TextInput
              label={wordings.areaId}
              value={areaId}
              onChange={(value) => setAreaId(value)}
              required
            />
            <TextInput
              label={wordings.areaName}
              value={areaName}
              onChange={(value) => setAreaName(value)}
              required
            />
            <Dropdown
              label={wordings.secondLanguage}
              value={language}
              onChange={(value) => setLanguage(value)}
              options={LANGUAGES}
            />
            <div className={styles["settings__button-container"]}>
              {initialSetupCompleted && (
                <Button cancelButton onClick={onClose}>
                  {wordings.back}
                </Button>
              )}
              <Button
                onClick={() => {
                  if (areaId && token) {
                    localStorage.setItem("token", token);
                    localStorage.setItem("areaId", areaId);
                    if (language)
                      localStorage.setItem(
                        "language",
                        JSON.stringify(language)
                      );
                    localStorage.setItem("areaName", areaName);

                    if (!initialSetupCompleted)
                      toggleInitialSetupCompleted(true);

                    if (isEdit) toggleEdit(false);
                  }
                }}
                disabled={!areaId || !token}
              >
                {wordings.save}
              </Button>
            </div>
          </div>
        )}
        {!isEdit && initialSetupCompleted && (
          <div className={styles["settings__details"]}>
            <div className={styles["settings__details-data"]}>
              <label>{wordings.token}</label>
              <label>{token}</label>
            </div>
            <div className={styles["settings__details-data"]}>
              <label>{wordings.areaId}</label>
              <label>{areaId}</label>
            </div>
            <div className={styles["settings__details-data"]}>
              <label>{wordings.areaName}</label>
              <label>{areaName}</label>
            </div>
            <div className={styles["settings__details-data"]}>
              <label>{wordings.secondLanguage}</label>
              <label>{language ? language.label : wordings.default}</label>
            </div>
            <div className={styles["settings__button-container"]}>
              <Button cancelButton onClick={() => toggleOpen(false)}>
                {wordings.close}
              </Button>
              <Button onClick={() => toggleEdit(true)}>{wordings.edit}</Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default Settings;
