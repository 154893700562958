import { joinClass } from "../../helpers/utils";
import styles from "./index.module.css";

const TextInput = ({
  value,
  onChange,
  type,
  label,
  dark,
  autoFocus,
  required,
  className,
  inputRef,
  disabled,
  ...props
}) => {
  return (
    <div
      className={joinClass(
        styles["text-input"],
        dark && styles["text-input--dark"],
        className && className
      )}
    >
      {label && (
        <label className={styles["text-input__label"]}>
          {label}
          {required && (
            <span className={styles["text-input__required"]}>{" *"}</span>
          )}
        </label>
      )}
      <input
        className={joinClass(
          styles["text-input__input"],
          dark && styles["text-input__input--dark"]
        )}
        type={type}
        value={value}
        autoFocus={autoFocus}
        required={required}
        onChange={(event) => onChange(event.target.value)}
        ref={inputRef}
        disabled={disabled}
        {...props}
      />
    </div>
  );
};

TextInput.defaultProps = {
  type: "text",
};

export default TextInput;
