import React, { Fragment } from "react";
import styles from "./index.module.css";

const Modal = ({ children, isOpen, onCancel }) => {
  return (
    isOpen && (
      <Fragment>
        <div
          className={styles["back-drop"]}
          onClick={() => {
            onCancel(false);
          }}
        />
        <div className={styles["modal"]}>
          <div className={styles["content"]}>{children}</div>
        </div>
      </Fragment>
    )
  );
};

export default Modal;
