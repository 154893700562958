import React, { useEffect, useState } from "react";
import { languageSelection } from "../../../helpers/utils";
import styles from "./greeting.module.css";
import { updateTimeOfDay } from "../../../helpers/updateTime";

const Greeting = ({ language }) => {
  const [timeOfDayGreeting, setTimeOfDayGreeting] = useState("");

  useEffect(() => {
    const updateGreeting = () => {
      setTimeOfDayGreeting(updateTimeOfDay());
    };

    updateGreeting();
    const intervalId = setInterval(updateGreeting, 60000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <h1 className={styles["home-greeting"]}>
      {languageSelection(language, timeOfDayGreeting)}
    </h1>
  );
};

export default Greeting;
